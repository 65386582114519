import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Dashboard,
  Assessment,
  Build,
  CompareArrows,
  Work,
  SupervisorAccount,
  Description,
  ChevronLeft,
  TroubleshootOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";

const NavigationSidebar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(!isMobile);
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();
  const [hasUpgraded, setHasUpgraded] = useState(false);

  useEffect(() => {
    const checkUpgradeStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/user/${userId}/upgrade-status`
        );
        setHasUpgraded(response.data.hasUpgraded);
      } catch (error) {
        console.error("Error checking upgrade status:", error);
      }
    };

    if (userId) {
      checkUpgradeStatus();
    }
  }, [userId]);

  const menuItems = [
    {
      text: "Dashboard",
      icon: <Dashboard />,
      path: `/dashboard/${userId}`,
      alwaysShow: true,
    },
    {
      text: "Career Analysis",
      icon: <Assessment />,
      path: `/report/${userId}`,
      alwaysShow: true,
    },
    {
      text: "Career Summary",
      icon: <TroubleshootOutlined />,
      path: `/report/${userId}?showSummary=true`,
      alwaysShow: false,
    },
    {
      text: "Cheat Sheets",
      icon: <Build />,
      path: `/cheat-sheets/${userId}`,
      alwaysShow: false,
    },
    {
      text: "Mid Career Switch",
      icon: <CompareArrows />,
      path: `/mid-career-switching/${userId}`,
      alwaysShow: false,
    },
    {
      text: "Land Dream Job",
      icon: <Work />,
      path: `/land-your-dream-job/${userId}`,
      alwaysShow: false,
    },
    {
      text: "Special Coaching",
      icon: <SupervisorAccount />,
      path: `/special-coaching/${userId}`,
      alwaysShow: false,
    },
    {
      text: "Resume Samples",
      icon: <Description />,
      path: `/resume-templates/${userId}`,
      alwaysShow: false,
    },
  ];

  const drawerWidth = open ? "240px" : "60px";

  const handleItemClick = (item) => {
    if (!hasUpgraded && !item.alwaysShow) {
      navigate(`/benefits/${userId}`);
    } else {
      navigate(item.path);
    }
    if (isMobile) setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setOpen(!open)}
        sx={{
          position: "fixed",
          left: open ? drawerWidth : "0px",
          top: "40px",
          zIndex: 1300,
          bgcolor: "background.paper",
          "&:hover": { bgcolor: "action.hover" },
        }}
      >
        {open ? <ChevronLeft /> : <MenuIcon />}
      </IconButton>

      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        open={isMobile ? open : true}
        onClose={() => setOpen(false)}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            transition: theme.transitions.create("width", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.enteringScreen,
            }),
          },
        }}
      >
        <Box sx={{ mt: 6 }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.text}
                onClick={() => handleItemClick(item)}
                selected={location.pathname === item.path}
                sx={{
                  opacity: hasUpgraded || item.alwaysShow ? 1 : 0.7,
                }}
              >
                <ListItemIcon>
                  {!hasUpgraded && !item.alwaysShow ? (
                    <LockOutlined />
                  ) : (
                    item.icon
                  )}
                </ListItemIcon>
                {open && (
                  <ListItemText
                    primary={item.text}
                    secondary={
                      !hasUpgraded && !item.alwaysShow
                        ? "Upgrade Required"
                        : null
                    }
                  />
                )}
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </>
  );
};

export default NavigationSidebar;
