import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  useTheme,
  useMediaQuery,
  Card,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const benefitsData = [
  {
    feature: "Career Analysis Report",
    free: true,
    premium: true,
    description: "Basic career analysis and insights",
  },
  {
    feature: "Career Scorecard",
    free: false,
    premium: true,
    description: "One-page summary of your career report",
  },
  {
    feature: "Job Hunt Toolkit",
    free: false,
    premium: true,
    description: "Useful tools and shortcuts for job hunting",
  },
  {
    feature: "Mid-Career Switch Course",
    free: false,
    premium: true,
    description: "Online course with 5-step framework",
  },
  {
    feature: "Dream Job Course",
    free: false,
    premium: true,
    description: "Comprehensive guide to landing your ideal role",
  },
  {
    feature: "Resume Templates",
    free: false,
    premium: true,
    description: "Industry-specific resume samples",
  },
  {
    feature: "Personalized Coaching",
    free: false,
    premium: true,
    description: "Access to career coaching at member rates",
  },
];

const BenefitsComparison = ({ variant = "dashboard", userId }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUpgrade = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams({
            userId: userId,
            amount: 1890,
            productName: "My CoachCAROL Premium Membership!",
          }),
        }
      );
      const { id: sessionId } = await response.json();
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId });
    } catch (error) {
      console.error("Error creating checkout session:", error);
    }
  };

  const renderIcon = (value) => {
    return value ? (
      <CheckCircleIcon sx={{ color: "success.main" }} />
    ) : (
      <CancelIcon sx={{ color: "error.main" }} />
    );
  };

  return (
    <Card
      sx={{
        p: 3,
        backgroundColor: variant === "dashboard" ? "#F2F6FF" : "white",
        borderRadius: 3,
      }}
    >
      {/* <Typography variant="h5" gutterBottom align="center" sx={{ mb: 4 }}>
        Membership Benefits Comparison
      </Typography> */}

      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "#8DDAF0" }}>
              <TableCell sx={{ fontWeight: "bold" }}>Features</TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Free
              </TableCell>
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Premium <br /> $49.90
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {benefitsData.map((row) => (
              <TableRow key={row.feature}>
                <TableCell>
                  <Box>
                    <Typography variant="body1">{row.feature}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {row.description}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="center">{renderIcon(row.free)}</TableCell>
                <TableCell align="center">{renderIcon(row.premium)}</TableCell>
              </TableRow>
            ))}

            <TableRow>
              <TableCell>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "bold",
                    color: "#FF0000",
                    backgroundColor: "#FFEB3B",
                    display: "inline-block",
                    padding: "2px 8px",
                    borderRadius: "4px",
                  }}
                >
                  Use Code "AGILEPROMO" for $30 Off
                </Typography>
              </TableCell>
              <TableCell />
              <TableCell align="center">
                <Button
                  variant="contained"
                  onClick={handleUpgrade}
                  sx={{
                    backgroundColor: "#000000",
                    "&:hover": {
                      backgroundColor: "#333333",
                    },
                  }}
                >
                  Upgrade Now
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default BenefitsComparison;
