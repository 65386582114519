import React, { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { Box, Typography, Button, Grid, Divider } from "@mui/material";
import html2pdf from "html2pdf.js";
import axios from "axios";
import "./SummaryPage.css"; // Import the CSS file
import { ForkLeft } from "@mui/icons-material";
import PDFSummaryPage from "./PDFSummaryPage";
import NavigationSidebar from "./NavigationSidebar";

const titleIconMap = {
  careerWellnessScore: "/images/SummaryIcons/01 - Career Wellness.png",
  careerHappinessScore: "/images/SummaryIcons/02 Career Happiness Score.png",
  careerStrategyScore: "/images/SummaryIcons/03 Career Strategy Score.png",
  careerAgilityScore: "/images/SummaryIcons/04 Career Agility Score.png",
  skillsClarityScore: "/images/SummaryIcons/05 Skills Clarity Score.png",
  workEnvironmentScore: "/images/SummaryIcons/06 Work Environment Score.png",
  workContentSatisfactionScore:
    "/images/SummaryIcons/07 Work Content Satisfaction Score.png",
  careerDisruptionReadinessScore:
    "/images/SummaryIcons/08 Career Disruption.png",
  networkingProficiencyScore:
    "/images/SummaryIcons/09 Networking Proficiency Score.png",
  workLifeBalanceScore: "/images/SummaryIcons/10 Work Life Balance Score.png",
  salarySatisfactionScore:
    "/images/SummaryIcons/11 Salary Satisfaction Score.png",
  satisfactionWithCurrentWorkloadScore:
    "/images/SummaryIcons/12 Satisfaction With Current Workload.png",
  jobSecurityScore: "/images/SummaryIcons/13 Job Security.png",
};

const renderCareerStage = (stage) => {
  let text = "";
  if (stage === 2) {
    text =
      "Accelerator: Your immediate desire is to progress your career to the next step in terms of skills, responsibilities and/or salary.";
  } else if (stage === 1) {
    text =
      "Urgent Role-Seeker: You are searching for the next role urgently and would like to land a new one soon.";
  } else if (stage === 0) {
    text =
      "Crossroader: You are at a point in your career where you are trying to figure out what your next move should be.";
  } else {
    text = "N/A";
  }

  return (
    <Box mb={2} display="flex" alignItems="center">
      <img
        src="/images/SummaryIcons/14 Career Stage.png"
        alt="Career Stage"
        className="title-icon"
      />
      <Box ml={2}>
        <Typography variant="subtitle1" align="left">
          Career Stage
        </Typography>
        <Typography variant="subtitle2" align="left">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const renderCareerVelocity = (stage) => {
  let text = "";
  if (stage === 2) {
    text =
      "You are a Career Accelerator and plan to progress your career in the near future.";
  } else if (stage === 1) {
    text =
      "You intend to Hover and stay put at the moment or make a lateral move.";
  } else if (stage === 0) {
    text =
      "You plan to Downshift and take things a bit easier from here onwards to slow down your career.";
  } else {
    text = "N/A";
  }
  return (
    <Box mb={2} display="flex" alignItems="center">
      <img
        src="/images/SummaryIcons/15 Career Velocity.png"
        alt="Career Stage"
        className="title-icon"
      />{" "}
      <Box ml={2}>
        <Typography variant="subtitle1" align="left">
          Career Velocity
        </Typography>
        <Typography variant="subtitle2" align="left">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const renderCareerPivot = (stage) => {
  let text = "";
  if (stage === 1) {
    text =
      "You are considering remaining in the same industry and in the same role.";
  } else if (stage === 2) {
    text =
      "You are considering staying in the same role but switching to another industry.";
  } else if (stage === 3) {
    text =
      "You are considering staying in your current industry but switching roles.";
  } else {
    text =
      "You are considering switching into an entirely new area that is different from your current role or industry.";
  }
  return (
    <Box mb={2} display="flex" alignItems="center">
      <img
        src="/images/SummaryIcons/16 Crossover Quadrant Career Pivot.png"
        alt="Career Pivot"
        className="title-icon"
      />
      <Box ml={2}>
        <Typography variant="subtitle1" align="left">
          Career Pivot
        </Typography>
        <Typography variant="subtitle2" align="left">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

const toPascalCase = (str) => {
  return str
    .replace(/([A-Z])/g, " $1") // 1. Add space before capital letters
    .replace(/\s+/g, " ") // 3. Remove spaces
    .replace(/(^\w| \w)/g, (match) => match.toUpperCase()); // 2. Capitalize the first letter of each word
};

const renderScoreBar = (score, label, index) => {
  const numericScore = Number(score);
  return (
    <Box mb={2}>
      <Box display="flex" alignItems="center">
        <img
          src={titleIconMap[label]}
          alt={`${label}`}
          className="title-icon"
        />
        <Box flexGrow={1} ml={2}>
          <Typography variant="subtitle1" align="left">
            {index + 1}. {toPascalCase(label)}{" "}
          </Typography>
          <Typography variant="body2" align="right">
            {isNaN(numericScore) ? "N/A" : numericScore.toFixed(1)}/5.0
          </Typography>
          <Box width="100%" bgcolor="#e0e0e0" height={5} borderRadius={5}>
            <Box
              width={`${(numericScore / 5) * 100}%`}
              bgcolor={numericScore < 2.5 ? "red" : "#1976d2"}
              height={5}
              borderRadius={5}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const SummaryPage = ({ summary, userId }) => {
  const [isPrinting, setIsPrinting] = useState(false);
  const downloadButtonRef = useRef(null);
  const [isGeneratingPDF, setIsGeneratingPDF] = useState(false);

  const handleDownloadPdf = () => {
    setIsPrinting(true);
    setIsGeneratingPDF(true);

    const pdfContent = <PDFSummaryPage summary={summary} />;
    const element = document.createElement("div");
    ReactDOM.render(pdfContent, element);

    const opt = {
      margin: [0.3, 0.3, 0.3, 0.3],
      filename: "career_summary.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, width: 950 }, // Set a fixed width for desktop layout
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .save()
      .then(() => {
        setIsPrinting(false);
        setIsGeneratingPDF(false);
      });
  };

  const handleSendEmail = async () => {
    const element = document.getElementById("summary-content");
    const opt = {
      margin: 0,
      filename: "career_summary.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "a4", orientation: "portrait" },
    };

    try {
      const pdf = await html2pdf().from(element).set(opt).outputPdf("blob");
      const formData = new FormData();
      formData.append("file", pdf, "career_summary.pdf");

      await axios.post(
        `${process.env.REACT_APP_API_URL}/useranswers/summary/${userId}/email`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("Summary sent to your email!");
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email. Please try again.");
    }
  };

  const firstColumnItems = [
    "careerWellnessScore",
    "careerHappinessScore",
    "careerStrategyScore",
    "careerAgilityScore",
    "skillsClarityScore",
    "workEnvironmentScore",
    "workContentSatisfactionScore",
  ];

  const secondColumnItems = [
    "careerDisruptionReadinessScore",
    "networkingProficiencyScore",
    "workLifeBalanceScore",
    "salarySatisfactionScore",
    "satisfactionWithCurrentWorkloadScore",
    "jobSecurityScore",
  ];

  return (
    <Box sx={{ display: "flex" }}>
      <NavigationSidebar userId={userId} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${0}px)` },
          ml: { sm: `${0}px` },
        }}
      >
        <div className="pdf-content">
          <Box p={2} id="summary-content" className="pdf-content">
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <img
                  src="/images/Logo/cai logo.jpg"
                  alt="Company Logo"
                  style={{
                    maxWidth: "100px",
                    marginRight: "10px",
                  }}
                  align="left"
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Typography
                    variant="h5"
                    gutterBottom
                    align="left"
                    fontWeight={"bold"}
                  >
                    Hi {summary.userName}, Here is your Personalised Career
                    Wellness Report Summary
                  </Typography>
                  <Typography variant="body2" gutterBottom align="left">
                    As you know, our careers are very important to us and having
                    a good and well-balanced career is essential for our
                    personal well-being. Here are the details of your Career
                    Wellness Assessment:
                  </Typography>
                  {/* <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="left"
                    fontWeight={"bold"}
                  >
                    Name: {summary.userName}
                  </Typography> */}
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="left"
                    fontWeight={"bold"}
                  >
                    Email: {summary.userEmail}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="left"
                    fontWeight={"bold"}
                  >
                    {`Report Date: ${new Date().toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={2} paddingBottom={1}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  align="right"
                  fontWeight={"bold"}
                >
                  Your Career Avatar is {summary.careerAvatar}
                </Typography>
                {summary.careerAvatar && (
                  <img
                    src={`/images/Career Avatars/Square - ${summary.careerAvatar}.jpg`}
                    alt={`${summary.careerAvatar} Avatar`}
                    style={{ maxWidth: "80px", float: "right" }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} style={{ display: "flex" }}>
                <Box
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {renderCareerStage(summary.careerStage)}
                </Box>
              </Grid>
              <Grid item xs={12} md={4} style={{ display: "flex" }}>
                <Box
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {renderCareerVelocity(summary.careerVelocity)}
                </Box>
              </Grid>

              <Grid item xs={12} md={4} style={{ display: "flex" }}>
                <Box
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  {renderCareerPivot(summary.careerPivot)}
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Your Internal Career Scores
                </Typography>
                <Grid container spacing={2}>
                  {[
                    "careerWellnessScore",
                    "careerHappinessScore",
                    "careerStrategyScore",
                    "careerAgilityScore",
                    "skillsClarityScore",
                  ].map(
                    (key, index) =>
                      summary[key] !== undefined && (
                        <Grid item xs={12} sm={6} key={key}>
                          <Box
                            mb={1}
                            style={{
                              border: "1px solid black",
                              padding: "4px",
                            }}
                          >
                            {renderScoreBar(summary[key], key, index)}
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Your Key Career Satisfiers
                </Typography>
                <Grid container spacing={2}>
                  {[
                    "salarySatisfactionScore",
                    "workEnvironmentScore",
                    "workContentSatisfactionScore",
                    "workLifeBalanceScore",
                    "satisfactionWithCurrentWorkloadScore",
                    "jobSecurityScore",
                  ].map(
                    (key, index) =>
                      summary[key] !== undefined && (
                        <Grid item xs={12} sm={6} key={key}>
                          <Box
                            mb={1}
                            style={{
                              border: "1px solid black",
                              padding: "4px",
                            }}
                          >
                            {renderScoreBar(summary[key], key, index)}
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" align="left">
                  Your External Career Scores
                </Typography>
                <Grid container spacing={2}>
                  {[
                    "networkingProficiencyScore",
                    "careerDisruptionReadinessScore",
                  ].map(
                    (key, index) =>
                      summary[key] !== undefined && (
                        <Grid item xs={12} sm={6} key={key}>
                          <Box
                            mb={1}
                            style={{
                              border: "1px solid black",
                              padding: "4px",
                            }}
                          >
                            {renderScoreBar(summary[key], key, index)}
                          </Box>
                        </Grid>
                      )
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    border: "1px solid black",
                    padding: "8px",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  <img
                    src="/images/SummaryIcons/17 Disatisfiers Header.png"
                    alt="Your potential disatisfiers are"
                    className="title-icon"
                  />
                  <Box ml={2}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      align="left"
                      color="error"
                      fontWeight={"bold"}
                    >
                      Your Career Disatisfiers or Areas for Further Exploration:
                    </Typography>
                    {summary.topNegativeWorkSatisfiers.map((item, index) => {
                      let displayTitle = item.title;
                      switch (item.title) {
                        case "Job Security":
                          displayTitle =
                            "It seems you may be feeling a bit insecure about your job stability.";
                          break;
                        case "Work":
                          displayTitle =
                            "It seems the nature of your current work could be a possible source of dissatisfaction for you.";
                          break;
                        case "Team":
                          displayTitle =
                            "It seems that you might be experiencing some unhappiness with the team you're working with.";
                          break;
                        case "Personal Happiness":
                          displayTitle =
                            "Your results suggest that you might not be too happy in your current job.";
                          break;
                        case "Salary":
                          displayTitle =
                            "Your results indicate you could be dissatisfied with your current salary.";
                          break;
                        default:
                          break;
                      }
                      return (
                        <Typography variant="body2" align="left" key={index}>
                          {index + 1}. {displayTitle}
                        </Typography>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Footer Section */}
            <footer style={{ textAlign: "left", marginTop: "20px" }}>
              <br></br>
              <Divider />
              <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                {summary.userName}, look after your Career well and continue
                striving for Career Success!{" "}
              </p>
              <p>
                If you have any questions regarding this report, please email us
                at info@CareerAgility.ai, or book your specially arranged
                appointment with us using the promotional code below:{" "}
              </p>
              <div
                style={{
                  padding: "4px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <img
                      src="/images/QRCode/Promo Price Image 3.jpg"
                      alt="QR Code"
                      style={{ height: "120px" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                  }}
                >
                  <a href="https://calendly.com/adrian-careeragility/coachcarol-careerclinic?month=2024-09">
                    Or click this link to book your appointment
                  </a>
                </div>
              </div>
              <p>
                © Copyright, Career Agility International Pte Ltd, 2017-
                {new Date().getFullYear()}, All rights reserved. No part of this
                report is to be copied or duplicated without the express written
                permission of the copyright holder.{" "}
              </p>
            </footer>
          </Box>
          <Box mt={3}>
            {!isGeneratingPDF && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadPdf}
                style={{ marginRight: "10px" }}
                className="download-pdf-button"
              >
                Download PDF
              </Button>
            )}
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default SummaryPage;
