import React from "react";
import { Box, Container, Typography } from "@mui/material";
import BenefitsComparison from "./BenefitsComparison";
import { useParams } from "react-router-dom";

const BenefitsPage = () => {
  const { userId } = useParams();

  return (
    <Container maxWidth="lg">
      <Box sx={{ py: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom align="center">
          Premium Membership Benefits
        </Typography>
        <BenefitsComparison variant="page" userId={userId} />
      </Box>
    </Container>
  );
};

export default BenefitsPage;
