import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  Grid,
  CardMedia,
} from "@mui/material";
import WorkIcon from "@mui/icons-material/Work";
import PlayCircleFilledRoundedIcon from "@mui/icons-material/PlayCircleFilledRounded";
import VideoPlayer from "./VideoPlayer";
import { useNavigate, useParams } from "react-router-dom";
import NavigationSidebar from "../NavigationSidebar";

const LandDreamJob = () => {
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [openPlayer, setOpenPlayer] = useState(false);
  const navigate = useNavigate();
  const { userId } = useParams();

  const videos = [
    {
      number: "1",
      title: "So, you want to land your Dream Job",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069669/00_-_Introduction_FINAL_tk8gr8.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724069669/00_-_Introduction_FINAL_tk8gr8.jpg",
    },
    {
      number: "2",
      title: "What is a Career Strategy",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071450/1_-_Career_Strategy_FINAL_Compressed_yoqitc.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724071450/1_-_Career_Strategy_FINAL_Compressed_yoqitc.jpg",
    },
    {
      number: "3",
      title: "How to Build Your Professional Brand",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071481/2_-_Branding_FINAL_pt1lwj.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724071481/2_-_Branding_FINAL_pt1lwj.jpg",
    },
    {
      number: "4",
      title: "LinkedIn Strategies That Get You Hired",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069681/3_-_Linkedin_Strategies_FINAL_tavqjm.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724069681/3_-_Linkedin_Strategies_FINAL_tavqjm.jpg",
    },
    {
      number: "5",
      title: "Build Your Job Search Resilience",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069678/6_-_Resilience_FINAL_wpikjo.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724069678/6_-_Resilience_FINAL_wpikjo.jpg",
    },
    {
      number: "6",
      title: "How to Network Strategically",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071781/5_-_Strategic_Networking_FINAL_e10rzi.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724071781/5_-_Strategic_Networking_FINAL_e10rzi.jpg",
    },
    {
      number: "7",
      title: "Interviewing Techniques That Get You Hired",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724071856/7_-_Interviewing_Basics_FINAL_pvrwbj.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724071856/7_-_Interviewing_Basics_FINAL_pvrwbj.jpg",
    },
    {
      number: "8",
      title: "Resume Tips That Get You Noticed",
      url: "https://res.cloudinary.com/dncu0dqbk/video/upload/v1724069682/8_-_Resume_Basics_FINAL_a3aopz.mp4",
      thumbnail:
        "https://res.cloudinary.com/dncu0dqbk/video/upload/so_2.0/v1724069682/8_-_Resume_Basics_FINAL_a3aopz.jpg",
    },
  ];

  const handleVideoClick = (video) => {
    setSelectedVideo(video);
    setOpenPlayer(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <NavigationSidebar userId={userId} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${0}px)` },
          ml: { sm: `${0}px` },
        }}
      >
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#FFFFFF",
            boxShadow: "none",
          }}
        >
          <CardContent sx={{ flexGrow: 1 }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography gutterBottom variant="h5" component="div">
                Land Your Dream Job
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" paragraph>
              Jobhunting in today's economy can be difficult and sometimes
              discouraging.
              <br />
              <br />
              What you need to land your dream job successfully is a Job Search
              Strategy and some powerful "Insider Tips".
              <br />
              <br />
              Watch our online course and enjoy your next role faster!
            </Typography>
            <Box
              sx={{
                backgroundColor: "#8DDAF0",
                margin: "0 -24px",
                padding: "0 24px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{ backgroundColor: "#8DDAF0", p: 2, borderRadius: 1 }}
              >
                {videos.map((video, index) => {
                  return (
                    <Grid item xs={12} sm={6} md={4} key={index} mb={4}>
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "#FFFFFF",
                          cursor: "pointer",
                          pl: 3,
                          pr: 3,
                          pt: 3,
                          borderRadius: 5,
                        }}
                        onClick={() => handleVideoClick(video)}
                      >
                        <Box sx={{ position: "relative" }}>
                          <CardMedia
                            component="img"
                            height="300"
                            image={video.thumbnail}
                            alt={video.title}
                            sx={{ borderRadius: 5 }}
                          />
                          <PlayCircleFilledRoundedIcon
                            sx={{
                              position: "absolute",
                              bottom: 8,
                              left: 8,
                              color: "#415ED4",
                              fontSize: 60,
                              backgroundColor: "#ffffff",
                              borderRadius: "50%",
                              "&:hover": {
                                transform: "scale(1.1)",
                                transition: "transform 0.2s",
                              },
                            }}
                          />
                        </Box>
                        <CardContent
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            height: "60px",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{
                              height: "60px",
                              overflow: "hidden",
                              display: "flex",
                              alignItems: "center",
                              gap: 2,
                            }}
                          >
                            <Box
                              sx={{
                                backgroundColor: "#000",
                                color: "#fff",
                                width: "30px",
                                height: "30px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "4px",
                                flexShrink: 0,
                              }}
                            >
                              {video.number}
                            </Box>
                            <Box
                              sx={{
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitLineClamp: 2,
                                WebkitBoxOrient: "vertical",
                              }}
                            >
                              {video.title}
                            </Box>
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <VideoPlayer
          open={openPlayer}
          onClose={() => setOpenPlayer(false)}
          videoUrl={selectedVideo?.url}
          title={selectedVideo?.title}
        />
      </Box>
    </Box>
  );
};

export default LandDreamJob;
