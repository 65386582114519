import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";

const PaymentContext = createContext();

export function PaymentProvider({ children }) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const initiatePayment = async (userId) => {
    setIsProcessing(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/create-checkout-session`,
        new URLSearchParams({ userId })
      );

      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: response.data.id });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsProcessing(false);
    }
  };

  const verifyPayment = async (sessionId, userId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/verify-payment/${sessionId}`
      );
      return response.data;
    } catch (err) {
      setError(err.message);
      return { success: false };
    }
  };

  return (
    <PaymentContext.Provider
      value={{
        isProcessing,
        error,
        initiatePayment,
        verifyPayment,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
}

export const usePayment = () => useContext(PaymentContext);
