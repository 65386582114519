import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
  CircularProgress,
  Snackbar,
} from "@mui/material";
import "./UpgradeCard.css";
import { usePayment } from "../contexts/PaymentContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const UpgradeCard = ({ userId, onUpgradeSuccess }) => {
  const { isProcessing, error, setError, initiatePayment, verifyPayment } = usePayment();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const paymentStatus = queryParams.get("payment");
    const sessionId = queryParams.get("session_id");

    if (paymentStatus === "success" && sessionId) {
      handlePaymentVerification(sessionId);
    }
  }, [location]);

  const handlePaymentVerification = async (sessionId) => {
    const result = await verifyPayment(sessionId, userId);
    if (result.success && result.hasUpgraded) {
      onUpgradeSuccess();
      navigate(`/report/${userId}`, { replace: true });
    }
  };

  const handleUpgrade = () => {
    initiatePayment(userId);
  };

  return (
    <Card className="upgrade-card">
      <Grid container>
        <Grid item xs={12} md={6}>
          <CardMedia
            component="img"
            src="/images/Upgrade Card/CAROL 2C.jpeg"
            alt="Career Path"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <CardContent>
            <Typography variant="h4" component="h1" align="left" gutterBottom>
              I hope you found the results useful
            </Typography>
            <Typography variant="body1" component="p" align="left" gutterBottom>
              I've reviewed your information and identified 11 additional areas
              you may need to explore. You can access these insights as well as
              dozens of useful Career Tools, Actual Resume Samples and Online
              Training Courses with a Premium Membership.
            </Typography>
            <Typography variant="body1" component="p" align="left" gutterBottom>
              {/* This service includes the 'Chat with CoachCarol™' feature,
              allowing you to discuss your personalized results or any career
              challenges you're currently facing with me.  */}
              Don't miss out—enjoy these benefits today!
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpgrade}
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CircularProgress size={24} />
              ) : (
                "Unlock Full Report"
              )}
            </Button>
          </CardContent>
        </Grid>
      </Grid>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Card>
  );
};

export default UpgradeCard;
