import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Container,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import Marquee from "react-fast-marquee"; // Need to install: npm install react-fast-marquee
import CallMadeIcon from "@mui/icons-material/CallMade";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PersonIcon from "@mui/icons-material/Person";
import TimelineIcon from "@mui/icons-material/Timeline";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./LandingPage.css";

localStorage.setItem("answersSubmitted", "false");

const lightColors = [
  "#FFFDD0", // Cream
  "#E6F3FF", // Light Sky Blue
  "#F0FFF0", // Honeydew
  "#FFF5E6", // Linen
  "#F0FFFF", // Azure
  "#FAFAD2", // Light Goldenrod Yellow
  "#E0FFFF", // Light Cyan
  "#F5F5DC", // Beige
  "#FFF0F5", // Lavender Blush
  "#F0F8FF", // Alice Blue
  "#F8F8FF", // Ghost White
  "#FFFAF0", // Floral White
  "#F5FFFA", // Mint Cream
  "#FFEFD5", // Papaya Whip
  "#E6E6FA", // Lavender
  "#FFF5EE", // Seashell
  "#F0E68C", // Khaki
  "#FFFFE0", // Light Yellow
  "#E0EEED", // Powder Blue
  "#FDF5E6", // Old Lace
];

const benefits = [
  {
    title: "Career Wellness Analysis",
    description:
      "Gain personalized insights from our AI-driven diagnostic tool with customised ideas designed to improve your career",
    image: "/images/landing page/careerwellnessanalysis.png",
  },
  {
    title: "Career Avatar Profile",
    description:
      "Discover your unique Career Avatar and get tailored advice based on your Career profile",
    image: "/images/landing page/careeravatarprofile.png",
  },
  {
    title: "Personalised Career Scorecard",
    description:
      "Receive a comprehensive and objective assessment of your Career strengths and weaknesses, enabling data-driven decisions for professional growth",
    image: "/images/landing page/personalisedcareerscorecard.png",
  },
  {
    title: "Job Hunting Kit",
    description:
      "Access powerful tools to enhance your Job Search, e.g. Salary Negotiation Guides, Target Market Maps, and many more",
    image: "/images/landing page/jobhuntingkit.png",
  },
  {
    title: "Resume Resources",
    description:
      "Unlock a growing collection of actual Resume Samples that match your industry and role for easy reference when developing your CV",
    image: "/images/landing page/resumeresources.png",
  },
  {
    title: "Online Video Courses",
    description:
      "Leverage the Power of Online Learning with a library of videos on Mid-Career Switching and Effective Job-Hunting Secrets",
    image: "/images/landing page/onlinevideocourses.png",
  },
];

const testimonials = [
  {
    name: "Sarah Chen",
    role: "Career Accelerator",
    company: "Microsoft",
    comment:
      "CoachCarol helped me identify my career velocity and provided a clear roadmap for my next career move.",
    // image: "/images/testimonials/sarah.jpg",
  },
  {
    name: "Michael Rodriguez",
    role: "Career Switcher",
    company: "Amazon",
    comment:
      "The career wellness assessment gave me the confidence to make a successful industry transition.",
    // image: "/images/testimonials/michael.jpg",
  },
  {
    name: "Emily Thompson",
    role: "Team Leader",
    company: "Adobe",
    comment:
      "The detailed analysis of my career satisfiers and dissatisfiers was eye-opening and helped me make better decisions.",
    // image: "/images/testimonials/emily.jpg",
  },
];

const companyLogos = [
  "/images/logo/landingpagecorp/grab.png",
  "/images/logo/landingpagecorp/fluence.png",
  "/images/logo/landingpagecorp/hsbc.png",
  "/images/logo/landingpagecorp/scheider.png",
  "/images/logo/landingpagecorp/merck.png",
];

const heroContent = {
  title: "Unlock Your Career Wellness",
  subtitle: "",
  description:
    "Let CoachCarol™ analyze your career wellness, happiness, strategy, and agility to create a personalized development plan.",
};

const videoContent = {
  title: "See How CoachCarol™ Works",
  description:
    "Watch how our AI-powered career wellness assessment can help transform your professional journey",
  videoUrl:
    "https://res.cloudinary.com/dncu0dqbk/video/upload/v1734600437/CoachCarol_Explainer_Video_hywa5x.mp4", // Replace with your actual video URL
};

const ctaContent = {
  title: "Ready to Understand Your Career Health?",
  subtitle:
    "Join thousands of professionals who've discovered their career wellness scores and development opportunities with CoachCarol™",
  buttonText: "Start Free Assessment",
};

const LandingPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleStart = () => {
    setIsLoading(true);
    navigate("/questions");
  };

  return (
    <>
      <AppBar position="static" className="menu-bar">
        <Toolbar variant="regular">
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img
              src="./images/Logo/cai logo.jpg"
              alt="Company Logo"
              style={{ height: "32px" }}
            />
          </IconButton>
          <Button
            variant="outlined"
            onClick={() => navigate("/login")}
            endIcon={<CallMadeIcon />}
            sx={{
              borderRadius: "24px",
              marginLeft: "auto", // Add margin-left: auto to push button to right
              px: 4,
              py: 1,
              borderColor: "#000000",
              color: "#000000",
              "&:hover": {
                borderColor: "#000000",
                bgcolor: "rgba(23, 109, 147, 0.04)",
              },
            }}
          >
            Login
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          background: "linear-gradient(135deg, #f5f7fa 0%, #e4ecf7 100%)",
          pt: 8,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ mb: 6 }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: { xs: "2.5rem", md: "3.5rem" },
                    fontWeight: 800,
                    lineHeight: 1.2,
                    mb: 3,
                  }}
                >
                  {heroContent.title}
                  <Box
                    component="span"
                    sx={{
                      color: "#176d93",
                      display: "block",
                    }}
                  >
                    {heroContent.subtitle}
                  </Box>
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    color: "text.secondary",
                    mb: 4,
                    lineHeight: 1.6,
                  }}
                >
                  {heroContent.description}
                </Typography>
                <Button
                  variant="contained"
                  size="large"
                  onClick={handleStart}
                  endIcon={<CallMadeIcon />}
                  sx={{
                    borderRadius: "28px",
                    py: 2,
                    px: 6,
                    fontSize: "1.1rem",
                    textTransform: "none",
                    bgcolor: "#000000",
                    "&:hover": {
                      bgcolor: "#000000",
                    },
                  }}
                >
                  {ctaContent.buttonText}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    left: -20,
                    right: 20,
                    bottom: 20,
                    borderRadius: "16px",
                    background:
                      "linear-gradient(45deg, #176d93 0%, #2196f3 100%)",
                    opacity: 0.1,
                    zIndex: 0,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  src="/images/Landing Page/CAROL TITLE PIC.jpeg"
                  alt="Career Path"
                  sx={{
                    borderRadius: "16px",
                    position: "relative",
                    zIndex: 1,
                    boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box sx={{ py: 12, bgcolor: "#f8f9fa" }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              mb: 3,
              fontWeight: 700,
            }}
          >
            {videoContent.title}
          </Typography>
          <Typography
            variant="h5"
            textAlign="center"
            sx={{
              mb: 6,
              color: "text.secondary",
            }}
          >
            {videoContent.description}
          </Typography>
          <Box
            sx={{
              position: "relative",
              paddingBottom: "56.25%", // 16:9 aspect ratio
              height: 0,
              overflow: "hidden",
              maxWidth: "900px",
              margin: "0 auto",
              borderRadius: "16px",
              boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
            }}
          >
            <iframe
              src={videoContent.videoUrl}
              title="CoachCarol Explainer Video"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                border: "none",
                borderRadius: "16px",
              }}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              controlsList="nodownload"
              allowFullScreen
            />
          </Box>
        </Container>
      </Box>

      {/* Benefits Section */}
      <Box sx={{ py: 15 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            textAlign="center"
            sx={{
              mb: 8,
              fontWeight: 700,
            }}
          >
            Why Choose{" "}
            <Box component="span" color="#176d93">
              CoachCarol™
            </Box>
          </Typography>
          <Grid container spacing={4}>
            {benefits.map((benefit, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: 4,
                    boxShadow: "0 10px 30px rgba(0,0,0,0.05)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-8px)",
                    },
                  }}
                >
                  <CardContent sx={{ p: 4 }}>
                    <Box sx={{ textAlign: "center", mb: 3 }}>
                      <img
                        src={benefit.image}
                        alt={benefit.title}
                        style={{
                          width: "auto",
                          height: "200px",
                          objectFit: "contain",
                          borderRadius: "8px",
                        }}
                      />
                    </Box>
                    <Typography variant="h5" gutterBottom fontWeight="700">
                      {benefit.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Companies Section with Gradient Background */}
      <Box
        sx={{
          py: 8,
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          sx={{
            mb: 6,
            fontWeight: 600,
            color: "text.secondary",
          }}
        >
          Trusted by Leading Companies
        </Typography>
        <Marquee gradient={false} speed={40}>
          {companyLogos.map((logo, index) => (
            <Box
              key={index}
              sx={{
                mx: 6,
                opacity: 1,
                transition: "all 0.3s ease",
                "&:hover": {
                  filter: "grayscale(0%)",
                  opacity: 1,
                },
              }}
            >
              <img src={logo} alt="Company Logo" height="200" />
            </Box>
          ))}
        </Marquee>
      </Box>

      {/* New Resource Library Section */}
      <Box sx={{ py: 12, bgcolor: "#f8f9fa" }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  mb: 4,
                  lineHeight: 1.3,
                }}
              >
                Use our Library of Actual Industry/Role Specific Resume Samples
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {[
                  "Audit",
                  "Compliance",
                  "Risk Management",
                  "Sales Leader",
                  "Business Support Manager",
                  "Marketing",
                  "Branding",
                  "FMCG",
                  "Finance Analyst",
                  "Finance Manager",
                  "Finance Director",
                  "HR Manager",
                  "HR Partner",
                  "HR Director",
                  "IT, Cloud and AI",
                  "Pharmaceutical Sales",
                  "Supply Chain",
                  "Operations Manager",
                  "and Many More",
                ].map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "white",
                      px: 2,
                      py: 1,
                      borderRadius: "50px",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: "#4CAF50",
                        mr: 1,
                        fontSize: "1.2rem",
                      }}
                    />
                    <Typography variant="body1">{tag}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    left: -20,
                    right: 20,
                    bottom: 20,
                    borderRadius: "16px",
                    background:
                      "linear-gradient(45deg, #176d93 0%, #2196f3 100%)",
                    opacity: 0.1,
                    zIndex: 0,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  src="/images/landing page/ResumeSamplesSection.png"
                  alt="Career Resources"
                  sx={{
                    borderRadius: "16px",
                    position: "relative",
                    zIndex: 1,
                    boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* New Resource Library Section */}
      <Box sx={{ py: 12, bgcolor: "#f8f9fa" }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            {/* Left Column */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 700,
                  mb: 4,
                  lineHeight: 1.3,
                }}
              >
                Access A Growing Library of Career Resources
              </Typography>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {[
                  "Negotiation Guide",
                  "LinkedIn Branding",
                  "Network Mapping",
                  "Interview Preparation",
                  "Salary Calculation",
                  "Offer & Acceptance Checklist",
                ].map((tag, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      bgcolor: "white",
                      px: 2,
                      py: 1,
                      borderRadius: "50px",
                      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                      transition: "transform 0.2s ease",
                      "&:hover": {
                        transform: "translateY(-2px)",
                      },
                    }}
                  >
                    <CheckCircleIcon
                      sx={{
                        color: "#4CAF50",
                        mr: 1,
                        fontSize: "1.2rem",
                      }}
                    />
                    <Typography variant="body1">{tag}</Typography>
                  </Box>
                ))}
              </Box>
            </Grid>

            {/* Right Column */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  "&::before": {
                    content: '""',
                    position: "absolute",
                    top: -20,
                    left: -20,
                    right: 20,
                    bottom: 20,
                    borderRadius: "16px",
                    background:
                      "linear-gradient(45deg, #176d93 0%, #2196f3 100%)",
                    opacity: 0.1,
                    zIndex: 0,
                  },
                }}
              >
                <CardMedia
                  component="img"
                  src="/images/landing page/careerresources.png"
                  alt="Career Resources"
                  sx={{
                    borderRadius: "16px",
                    position: "relative",
                    zIndex: 1,
                    boxShadow: "0 20px 40px rgba(0,0,0,0.1)",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Testimonials Section */}
      {/* <Box sx={{ bgcolor: "#f8f9fa", py: 15 }}>
        <Container maxWidth="lg">
          <Typography variant="h3" textAlign="center" mb={8}>
            Success Stories
          </Typography>
          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} mb={4} key={index}>
                <Card sx={{ height: "100%", borderRadius: 4, p: 3 }}>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                     <CardMedia
                      component="img"
                      sx={{ width: 60, height: 60, borderRadius: "50%", mr: 2 }}
                      image={testimonial.image}
                      alt={testimonial.name}
                    /> 
                    <Box>
                      <Typography variant="h6">{testimonial.name}</Typography>
                      <Typography variant="body2" color="text.secondary">
                        {testimonial.role} at {testimonial.company}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="body1">
                    "{testimonial.comment}"
                  </Typography>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box> */}

      {/* Additional Benefits Section */}
      <Box sx={{ bgcolor: "#000000", py: 15, color: "white" }}>
        <Container maxWidth="lg">
          <Typography variant="h3" textAlign="center" mb={8} color="white">
            Also Get Instant Access To
          </Typography>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            maxWidth="md"
            mx="auto"
            sx={{ px: { xs: 2, sm: 4, md: 6 } }} // Add responsive padding
          >
            {[
              "Industry Specific Resume Templates",
              "Mid-Career Switch Video Course",
              "Land Your Dream Job Video Course",
              "Job Hunting Toolkit with 9 Reference Guides",
            ].map((item, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    fontSize: "0.1rem",
                    border: "1px solid rgba(255, 255, 255, 0.2)",
                    borderRadius: "50px",
                    padding: "16px 24px",
                    margin: { xs: "8px", sm: "12px" }, // Add responsive margins
                    transition: "all 0.3s ease",
                    "&:hover": {
                      border: "1px solid rgba(255, 255, 255, 0.5)",
                      transform: "translateY(-2px)",
                      boxShadow: "0 4px 20px rgba(255, 255, 255, 0.1)",
                    },
                  }}
                >
                  <CheckCircleIcon
                    sx={{
                      color: "#4CAF50",
                      fontSize: "2rem",
                    }}
                  />
                  <Typography variant="h6" component="span">
                    {item}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Final CTA Section */}
      <Box sx={{ textAlign: "center", py: 15 }}>
        <Container maxWidth="md">
          <Typography variant="h3" gutterBottom>
            {ctaContent.title}
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            {ctaContent.subtitle}
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={handleStart}
            endIcon={<CallMadeIcon />}
            sx={{
              borderRadius: "28px",
              py: 2,
              px: 6,
              fontSize: "1.1rem",
              textTransform: "none",
              bgcolor: "#000000",
              "&:hover": {
                bgcolor: "#000000",
              },
            }}
          >
            {ctaContent.buttonText}
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default LandingPage;
