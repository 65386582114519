import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  CardMedia,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DescriptionIcon from "@mui/icons-material/Description";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TargetIcon from "@mui/icons-material/GpsFixed";
import PeopleIcon from "@mui/icons-material/People";
import SearchIcon from "@mui/icons-material/Search";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PaidIcon from "@mui/icons-material/Paid";
import ChecklistIcon from "@mui/icons-material/Checklist";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { useNavigate, useParams } from "react-router-dom";
import NavigationSidebar from "../NavigationSidebar";

const CheatSheets = () => {
  const navigate = useNavigate();
  const { userId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedSheet, setSelectedSheet] = useState(null);

  const sheets = [
    {
      name: "Your Compensation Negotion Guide ",
      path: "/landyourdreamjob/Your Compensation Negotiation Guide.pdf",
      description: "",
      icon: DescriptionIcon,
      bgColor: "#F2F6FF",
      color: "#415ED4",
    },
    {
      name: "Your LinkedIn and Branding Toolkit",
      path: "/landyourdreamjob/2-Your-LinkedIn-Branding-Cheatsheet.pdf",
      description: "Optimize your LinkedIn profile and personal brand",
      icon: LinkedInIcon,
      bgColor: "#E1F5F6",
      color: "#439E90",
    },
    {
      name: "Your Target Market Builder",
      path: "/landyourdreamjob/3-Your-Target-Market-Builder.pdf",
      description: "Identify and analyze your ideal job market",
      icon: TargetIcon,
      bgColor: "#F9F3D8",
      color: "#C38D3B",
    },
    {
      name: "Your Network Mapping Tool",
      path: "/landyourdreamjob/4-Your-Network-Mapping-Tool.pdf",
      description: "Map and leverage your professional network",
      icon: PeopleIcon,
      bgColor: "#FFF4F1",
      color: "#FF7954",
    },
    {
      name: "Your Job Market Research Pocket Guide",
      path: "/landyourdreamjob/5-Your-Market-Research-Pocket-Guide-1.pdf",
      description: "Research techniques for job market analysis",
      icon: SearchIcon,
      bgColor: "#F7FFE4",
      color: "#99BF3D",
    },
    {
      name: "Your Interview Preparation Guide",
      path: "/landyourdreamjob/6-Your-Interview-Preparation-Guide-1.pdf",
      description: "Comprehensive interview preparation strategies",
      icon: QuestionAnswerIcon,
      bgColor: "#F9E8FB",
      color: "#A03AAC",
    },
    {
      name: "Your Job Opportunities Tracker",
      path: "/landyourdreamjob/7-Your-Job-Opportunity-Tracker-1.pdf",
      description: "Track and manage your job applications",
      icon: TrackChangesIcon,
      bgColor: "#FBFBE8",
      color: "#9C9E51",
    },
    {
      name: "Your Salary Calculation Cheat-sheet",
      path: "/landyourdreamjob/8-Your-Salary-Calculator-1.pdf",
      description: "Calculate and compare compensation packages",
      icon: PaidIcon,
      bgColor: "#E5F9FE",
      color: "#36A5BE",
    },
    {
      name: "Your Offer & Acceptance Checklist",
      path: "/landyourdreamjob/9-Your-Offer-Acceptance-Checklist.pdf",
      description: "Evaluate and respond to job offers",
      icon: ChecklistIcon,
      bgColor: "#FFF1F7",
      color: "#BE366F",
    },
  ];

  const handleDownload = (sheet) => {
    setSelectedSheet(sheet);
    setOpenDialog(true);
  };

  const confirmDownload = () => {
    const link = document.createElement("a");
    link.href = selectedSheet.path;
    link.download = selectedSheet.name + ".pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setOpenDialog(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <NavigationSidebar userId={userId} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${0}px)` },
          ml: { sm: `${0}px` },
        }}
      >
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            bgcolor: "#8DDAF0",
          }}
        >
          <CardContent sx={{ flexGrow: 1, bgcolor: "#FFFFFF" }}>
            <Box display="flex" alignItems="center" mb={2}>
              <Typography gutterBottom variant="h5" component="div">
                Your Job Hunt Toolkit
              </Typography>
            </Box>
            <Typography variant="body2" color="text.secondary" paragraph>
              Here are nine of our best Career Tools that we've created to make
              your next steps as smooth as possible. <br />
              <br />
              Download these powerful insights and achieve your Career Goals
              faster!
            </Typography>
            <Box
              sx={{
                backgroundColor: "#8DDAF0",
                margin: "0 -24px",
                padding: "0 24px",
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  backgroundColor: "#8DDAF0",
                }}
              >
                {sheets.map((sheet, index) => {
                  const Icon = sheet.icon;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={3}
                      key={index}
                      sx={{
                        mb: 3,
                      }}
                    >
                      <Card
                        sx={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          borderRadius: 5,
                          overflow: "hidden",
                        }}
                      >
                        <CardMedia
                          sx={{
                            bgcolor: sheet.bgColor,
                            pt: 2,
                            pb: 2,
                            pl: 2,
                            m: 3,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "left",
                            position: "relative",
                            borderRadius: 5,
                          }}
                        >
                          <Box
                            sx={{
                              width: 56,
                              height: 56,
                              borderRadius: "50%",
                              bgcolor: sheet.color,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mb: 1,
                            }}
                          >
                            <Icon sx={{ fontSize: 32, color: "white" }} />
                          </Box>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ color: sheet.color }}
                          >
                            {sheet.name}
                          </Typography>
                        </CardMedia>
                        <CardContent sx={{ flexGrow: 1, pt: 2, pl: 3 }}>
                          <Typography
                            variant="body2"
                            color="text.secondary"
                            sx={{ mb: 2 }}
                          >
                            {sheet.description}
                          </Typography>
                          <Button
                            variant="contained"
                            startIcon={<DownloadIcon />}
                            onClick={() => handleDownload(sheet)}
                            sx={{
                              bgcolor: sheet.color,
                              borderRadius: 5,
                              "&:hover": {
                                bgcolor: `${sheet.color}CC`,
                              },
                            }}
                          >
                            Download
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            </Box>
          </CardContent>
        </Card>

        <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
          <DialogTitle>Download Cheat Sheet</DialogTitle>
          <DialogContent>
            <Typography paragraph>{selectedSheet?.name}</Typography>
            <Typography variant="body2" color="text.secondary">
              {selectedSheet?.description}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
            <Button onClick={confirmDownload} variant="contained">
              Download
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default CheatSheets;
